// external
import { Pipe, PipeTransform } from '@angular/core';

// core
import { SelfServMetaStatus } from '@aa/common';

/*
 * Formats a metaStatus enum
 * Usage:
 *   meta.metaStatus | metaStatus
 */
@Pipe({name: 'metaStatus'})
export class MetaStatusPipe implements PipeTransform {
  transform(type: SelfServMetaStatus): string {
    switch (type) {
      case SelfServMetaStatus.None:    return 'None';
      case SelfServMetaStatus.Pending: return 'Pending';
      case SelfServMetaStatus.Ok:    return 'OK';
      case SelfServMetaStatus.Error:   return 'Error';

      default:
        return `Unknown ${type}`;
    }
  }
}

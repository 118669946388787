/* tslint:disable:component-selector */
import { Component } from '@angular/core';


@Component({
  selector: '#aa-selfserv-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'selfserv';

  constructor() {
  }
}

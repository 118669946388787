import { Component } from '@angular/core';
import { AppConfig, ISelfServMeta } from '@aa/common';

import { SelfServMetaCache } from '@aa/selfserv/shared';

@Component({
  selector: 'aa-about-page',
  templateUrl: './about.component.html',
  host: {'class': 'anonymous-page'},
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {

  public readonly version: string;
  public readonly meta: ISelfServMeta;

  constructor(appConfig: AppConfig, metaCache: SelfServMetaCache) {
    this.version = 'v' + appConfig.version;
    this.meta = metaCache.meta;
  }
}

import { Injectable } from '@angular/core';
import { SelfServDataService, ISelfServMeta, AppConfig } from '@aa/common';

@Injectable({providedIn: 'root'})
export class SelfServMetaCache {

  meta: ISelfServMeta;
  errorStatus: number;

  constructor(private readonly dataService: SelfServDataService, private readonly appConfig: AppConfig) {}

  // called by app initializer in core module
  public init(selfServDomain?: string): Promise<void> {

    // first check global injected meta data
    if (this.setMeta(window['selfServMeta'])) {
      return Promise.resolve();
    }

    if (!selfServDomain) {
      // console.warn('selfServDomain is is undefined');
      return Promise.reject('selfServDomain is undefined');
    }

    return this.dataService.getMeta(selfServDomain).toPromise()
      .then(meta => {
        this.setMeta(meta);
      }, error => {
        this.errorStatus = error.status;
        console.warn('Failed to get self serv meta data', error);
      });
  }

  private setMeta(meta: ISelfServMeta): boolean {
    if (!meta) {
      return;
    }

    this.appConfig.setAnalyticsContext({
      teamName: meta.teamName,
      domain: meta.domain
    });

    this.meta = meta;
    return true;
  }
}

import { APP_BOOTSTRAP_LISTENER, APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule, SelfServMetaCache, SelfServAnalyticsService } from '@aa/selfserv/shared';
import { AppConfig } from '@aa/common';

import {polyfill as smoothscroll} from 'smoothscroll-polyfill';

import { environment } from '../../environments/environment';

export function appInitializer(appConfig: AppConfig): Function {
  return () => appConfig.init('selfserv', environment);
}

export function selfServMeta(metaCache: SelfServMetaCache): Function {
  return () => metaCache.init(environment.selfServDomain);
}

// null bootstrap function which is used force DI to generate singleton services
// we don't need to use the args
export function serviceBootstrap(): Function {
  return () => null;
}

export function initSmoothScroll(): Function {
  return () => {
    smoothscroll();
  };
}

// list of services which must be instantiated after bootstrap
const STARTUP_SERVICES = [
  SelfServAnalyticsService
];


@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [],
  exports: []
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, deps: [AppConfig], multi: true },
        { provide: APP_INITIALIZER, useFactory: selfServMeta, deps: [SelfServMetaCache], multi: true },

        { provide: APP_INITIALIZER, useFactory: initSmoothScroll, multi: true },

        { provide: APP_BOOTSTRAP_LISTENER, useFactory: serviceBootstrap, deps: STARTUP_SERVICES, multi: true}
      ]
    };
  }
}

// angular
import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

// TODO: understand why this doesn't get injected automatically when set sourceLocale to en-GB
// import { registerLocaleData } from '@angular/common';
// import locale from '@angular/common/locales/en-GB';
// registerLocaleData(locale);

// custom modules
import { AdviceAidCommonModule } from '@aa/common';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core';

// components
import { AppComponent } from './app.component';
import { PAGE_COMPONENTS } from './pages';
import { SharedModule } from './shared';

import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
import { ErrorHandler } from '@angular/core'

// configure Bugsnag ASAP
Bugsnag.start({ apiKey: '48b0a86a5783ee1c9e6ce926598e74e9' })
BugsnagPerformance.start({ apiKey: '48b0a86a5783ee1c9e6ce926598e74e9' })

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  return new BugsnagErrorHandler()
}

@NgModule({
  declarations: [
    AppComponent, ...PAGE_COMPONENTS
  ],
  imports: [
    AdviceAidCommonModule,
    BrowserModule,
    CoreModule.forRoot(), AppRoutingModule, SharedModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'en-GB'},
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}

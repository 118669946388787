<div id="main-content">

  <div class="logo"><img [src]="'adviceaid_logo.png' | imageSrc" alt="AdviceAid"></div>

  <h1 class="govuk-heading-xl">SelfServ</h1>
  <span class="govuk-caption-l">{{version}}</span>

  <table class="govuk-table meta-table" *ngIf="meta">
    <tbody class="govuk-table__body">
      <tr>
        <th scope="row" class="govuk-table__header">Domain</th>
        <td class="govuk-table__cell">{{meta.domain}}</td>
      </tr>
      <tr>
        <th scope="row" class="govuk-table__header">Team</th>
        <td class="govuk-table__cell">{{meta.teamName}}</td>
      </tr>
      <tr>
        <th scope="row" class="govuk-table__header">Timestamp</th>
        <td class="govuk-table__cell">{{meta.created | date:'medium'}}</td>
      </tr>
      <tr>
        <th scope="row" class="govuk-table__header">Status</th>
        <td class="govuk-table__cell">{{meta.metaStatus | metaStatus }}</td>
      </tr>
    </tbody>
  </table>

  <ng-template>
    <p>No SelfServ data available</p>
  </ng-template>
</div>



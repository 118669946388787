import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteHistoryService {

  private history: string[] = [];

  constructor(router: Router
  ) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history.push(urlAfterRedirects);
      });
  }

  public getHistoryUrl(relativeIndex: number): string {
    const length = this.history.length;
    const index = length + relativeIndex - 1;
    if (relativeIndex > 0 || index < 0) {
      return null;
    }
    return this.history[index];
  }

  public getCurrentUrl(): string {
    return this.getHistoryUrl(0);
  }

  public getPreviousUrl(): string {
    return this.getHistoryUrl(-1);
  }
}

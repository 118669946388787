// external
import { CanLoad, Router, RouterModule, Routes } from '@angular/router';
import { Injectable, NgModule} from '@angular/core';

import { ErrorComponent, NotFoundComponent, AboutComponent } from './pages';
import { SelfServMetaCache } from './shared';


@Injectable()
export class CanLoadQna implements CanLoad {
  constructor(private readonly cache: SelfServMetaCache,
              private readonly router: Router) {}

  canLoad(): boolean {
    if (this.cache.meta) {
      return true;
    }

    console.warn('selfserv meta data is is undefined');

    let errorRoute = '404';
    if (this.cache.errorStatus === 400 || this.cache.errorStatus >= 500) {
      errorRoute = 'error';
    }

    this.router.navigate([errorRoute], { skipLocationChange: true });
    return false;
  }
}

export const routes: Routes  = [
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: '404', component: NotFoundComponent
  },
  { path: '',
    canLoad: [CanLoadQna],
    loadChildren: () => import('./pages/+qna/qna_page.module').then(m => m.QnaPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: 'enabled'})],
  exports: [RouterModule],
  providers: [CanLoadQna]
})
export class AppRoutingModule { }

import { Injectable } from '@angular/core';

import { AnalyticsService, IQuestion, AdviceDoc } from '@aa/common';

const eventCategory: string = 'SelfServ';

/**
 * Centralised service for selfserv analytics calls
 */
@Injectable({providedIn: 'root'})
export class SelfServAnalyticsService {

  private readonly _eventCache = new Set<string>();

  constructor(private readonly analytics: AnalyticsService) {
  }

  /**
   * Fired the first time the questions page is hit
   * May be triggered multiple times per session
   * Note that related advice links will not trigger this event as they do not target the root question
   *
   * /questions => ✔
   * /questions/choose-a-topic => ✔
   *
   * /questions/choose-a-topic/2,1 => ✘
   * /questions/what-would-you-like-to-know => ✘
   *
   */
  public trackQuestionsStart(question: IQuestion, path: number[]) {
    if (question.entryPoint && path.length === 0) {
      this.analytics.trackEvent({
        category: eventCategory,
        action: 'aa_questions_start'
      });
    }
  }

  /**
   * "View Advice" clicked on Questions page
   * may be triggered multiple times per session
   */
  public trackQuestionsComplete() {
    this.analytics.trackEvent({
      category: eventCategory,
      action: 'Questions Complete'
    });
  }

  /**
   * Advice page is rendered
   * This could be triggered from full QnA journey or a direct link
   * Only one event allowed for given advice set per session
   */
  public trackAdviceView(adviceSetName: string) {
    if (!this.addToCache('Advice.View', adviceSetName)) {
      return;
    }

    this.analytics.trackEvent({
      category: eventCategory,
      action: 'aa_advice_view',
      label: adviceSetName
    });
  }

  /**
   * User downloads advice pdf
   */
  public trackPdfExport(doc: AdviceDoc): void {
    this.analytics.trackExportEvent('Pdf', doc.name);
  }

  /**
   * User sends advice email successfully
   */
  public trackEmailExport(doc: AdviceDoc): void {
    this.analytics.trackExportEvent('Email', doc.name);
  }

  /**
   * Error on Questions page
   * e.g. missing advice sheet
   * Only one event allowed for given error
   */
  public trackQuestionsError(message: string) {
    if (!this.addToCache('Questions.Error', message)) {
      return;
    }

    this.analytics.trackErrorEvent('Questions', message);
  }

  /**
   * Pdf export failure
   */
  public trackPdfError(doc: AdviceDoc, error: string) {
    this.analytics.trackErrorEvent('Pdf', `${doc.name}: ${error}`);
  }

  /**
   * Email failure
   */
  public trackEmailError(doc: AdviceDoc, error: string) {
    this.analytics.trackErrorEvent('Email', `${doc.name}: ${error}`);
  }

  /**
   * Adds the given key to the event cache, returns false if key already exists
   */
  private addToCache(prefix: string, key: string): boolean {
    const cacheKey = `${prefix}/${key}`;
    if (this._eventCache.has(cacheKey)) {
      return false;
    }

    this._eventCache.add(cacheKey);
    return true;
  }
}

import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { AdviceAidCommonModule } from '@aa/common';

import { COMPONENTS } from './components';
import { DIRECTIVES } from './directives';

@NgModule({
  imports:      [ CommonModule, RouterModule, AdviceAidCommonModule, ReactiveFormsModule ],
  declarations: [ ...COMPONENTS, ...DIRECTIVES],
  exports:      [
    CommonModule, RouterModule, AdviceAidCommonModule, ReactiveFormsModule,
    ...COMPONENTS, ...DIRECTIVES
  ],
  providers: []
})
export class SharedModule {

  constructor() {
  }
}
